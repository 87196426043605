import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col, Progress } from 'antd';
import { useParams } from 'react-router-dom';

import {
  VideoInterviewStatus,
  VideoQuestionType,
} from '../../constants/General';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { timeCounterByParamsFunc } from '../../utils/func';
import { Colors, Images } from '../../theme';
import {
  createVideoQuestionAction,
  selectQuestion,
  selectAnswerPayload,
} from '../../features/VideoQuestion/VideoQuestion.slice';

/* eslint-disable complexity */
const HeaderWrapper = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  z-index: 1;
  background-color: ${Colors.white};
  .ant-row {
    width: 100%;
  }
  .counter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .min-sec {
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.2px;
      margin-left: 5px;
    }
  }
  .progress {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .ant-progress {
      max-width: 510px;
      .ant-progress-inner {
        background-color: ${Colors.grey5};
        height: 14px;
        .ant-progress-bg {
          height: 14px !important;
          background: linear-gradient(225deg, #3186d4 0%, #29528f 100%);
        }
      }
      .ant-progress-text {
        font-size: 18px;
        line-height: 22px;
        color: ${Colors.primary};
      }
      .ant-progress-outer {
        margin-right: calc(-3em - 14px);
        padding-right: calc(3em + 14px);
      }
    }
  }
  .step-progress {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 12px;
    .ant-progress {
      position: relative;
      width: 165px;
      .ant-progress-inner {
        background-color: ${Colors.grey5};
        height: 27px;
        border-radius: 5px;
        .ant-progress-bg {
          height: 27px !important;
          background: #2c63a5;
          border-radius: 5px;
        }
      }
      .ant-progress-text {
        position: absolute;
        left: 5px;
        top: 0;
        font-size: 18px;
        line-height: 27px;
        color: ${Colors.white};
        font-weight: 700;
      }
      .ant-progress-outer {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
  @media (max-width: 576px) {
    padding: 15px 20px;
  }
`;

const Counter = ({
  startTime,
  endTime,
  code,
  questionId,
}: {
  startTime: number;
  endTime: number;
  questionId: number;
  code: string;
}) => {
  const [timeCounter, setTimeCounter] = useState(
    timeCounterByParamsFunc(endTime, startTime),
  );
  const counter = useRef<number>(startTime);
  const answerPayload = useAppSelector(selectAnswerPayload);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (endTime && (!counter.current || counter.current < endTime)) {
      window.headerCounter = setInterval(() => {
        const left = counter.current;
        counter.current = left + 1;
        const newTimeCounter = timeCounterByParamsFunc(
          endTime,
          counter.current,
        );
        if (
          newTimeCounter.hrs <= 0 &&
          newTimeCounter.mins <= 0 &&
          newTimeCounter.sec <= 0
        ) {
          clearInterval(window.headerCounter);
          window.headerCounter = null;
          dispatch(
            createVideoQuestionAction({
              code,
              question_id: questionId,
              ...answerPayload,
            }),
          );
        } else {
          setTimeCounter(newTimeCounter);
        }
      }, 1000);
    }

    return () => {
      if (window.headerCounter) {
        clearInterval(window.headerCounter);
        window.headerCounter = null;
      }
    };
  }, [endTime, answerPayload]);

  return (
    <div className="counter">
      <img src={Images.Counter} alt="header counter" />
      <span
        className="min-sec"
        style={{ color: timeCounter.mins < 5 ? 'red' : Colors.title }}
      >{`${
        timeCounter.mins >= 10 ? timeCounter.mins : `0${timeCounter.mins}`
      }:${
        timeCounter.sec >= 10 ? timeCounter.sec : `0${timeCounter.sec}`
      }`}</span>
    </div>
  );
};

const ProgressCmp = ({
  steps,
}: {
  steps: Array<{
    step: number;
    step_title: string;
    count: number;
    process: number;
  }>;
}) => {
  let total = 0;
  let current = 0;
  steps.forEach((p: any) => {
    total += p.count;
    current += p.process;
  });
  const percent = parseInt((((current - 1) / total) * 100).toFixed(), 10);
  return (
    <div className="progress">
      {steps.length > 0 && <Progress percent={percent} />}
    </div>
  );
};

const PartnerHeaderComponent = () => {
  const question = useAppSelector(selectQuestion);
  const { code }: { code: string } = useParams();
  return (
    <HeaderWrapper>
      <Row justify="start" align="middle">
        <Col md={6} sm={8} xs={12}>
          <img src={Images.Logo} alt="JetDevs" />
        </Col>
        {question &&
          question.step_status &&
          question.interview_status === VideoInterviewStatus.interviewing && (
            <Col md={12} sm={12} xs={0}>
              <ProgressCmp steps={question.step_status} />
            </Col>
          )}
        {question &&
          question.server_time &&
          question.interview_status === VideoInterviewStatus.interviewing && (
            <Col md={6} sm={4} xs={12}>
              <Counter
                startTime={question.server_time}
                endTime={question.current.deadline || 0}
                code={code}
                questionId={question.current.question_id}
              />
              {!!question.current.part_current &&
                !!question.current.part_count &&
                question.current.question_type !==
                  VideoQuestionType.stepIntro && (
                  <div className="step-progress">
                    <Progress
                      percent={parseInt(
                        (
                          (question.current.part_current /
                            question.current.part_count) *
                          100
                        ).toFixed(),
                        10,
                      )}
                      format={() =>
                        `${question.current.part_current} of ${question.current.part_count}`
                      }
                    />
                  </div>
                )}
            </Col>
          )}
      </Row>
    </HeaderWrapper>
  );
};

export default PartnerHeaderComponent;
