import { format, getUnixTime } from 'date-fns';
import { utcToZonedTime, format as formatTZ } from 'date-fns-tz';

const OneMin = 60;
const OneHour = 3600;

export const formatTimeStrByTimestamp = (timestamp: number) =>
  format(timestamp * 1000, 'MMM dd, yyyy HH:mm');

export const timeCounterFunc = (timestamp: number) => {
  const currentTimestamp = getUnixTime(new Date());
  if (!timestamp || timestamp <= currentTimestamp) {
    return {
      hrs: 0,
      mins: 0,
      sec: 0,
    };
  }
  const distanceSeconds = timestamp - currentTimestamp;
  const hrs = Math.floor(distanceSeconds / OneHour);
  const mins = Math.floor((distanceSeconds - hrs * OneHour) / OneMin);
  const sec = distanceSeconds - hrs * OneHour - mins * OneMin;
  return { hrs, mins, sec };
};

export const timeCounterByParamsFunc = (
  timestamp: number | null,
  current: number | null,
) => {
  if (!timestamp || !current || timestamp <= current) {
    return {
      hrs: 0,
      mins: 0,
      sec: 0,
    };
  }
  const distanceSeconds = timestamp - current;
  const hrs = Math.floor(distanceSeconds / OneHour);
  const mins = Math.floor((distanceSeconds - hrs * OneHour) / OneMin);
  const sec = distanceSeconds - hrs * OneHour - mins * OneMin;
  return { hrs, mins, sec };
};
/* eslint-disable complexity */
export const getDeveloperName = (
  firstName: string | null,
  lastName: string | null,
) => {
  if (firstName || lastName) {
    const nameArr = [];
    if (firstName) {
      nameArr.push(firstName);
    }
    if (lastName) {
      nameArr.push(lastName);
    }
    if (nameArr.length > 0) {
      return `${nameArr.join(' ')},`;
    }
    return '';
  }
  return '';
};

export const getDeveloperNameWithoutComma = (
  firstName: string | null,
  lastName: string | null,
) => {
  if (firstName || lastName) {
    const nameArr = [];
    if (firstName) {
      nameArr.push(firstName);
    }
    if (lastName) {
      nameArr.push(lastName);
    }
    if (nameArr.length > 0) {
      return `${nameArr.join(' ')}`;
    }
    return '';
  }
  return '';
};

export const formatTimestampByTimezone = (
  timestamp: number,
  formatString: string,
  timeZone: string,
) => {
  const startDateObj = new Date(timestamp * 1000);
  const startDateZone = utcToZonedTime(startDateObj, timeZone);
  return formatTZ(startDateZone, formatString, {
    timeZone,
  });
};

export const copyStringToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection()!.rangeCount > 0
      ? document.getSelection()!.getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection()!.removeAllRanges();
    document.getSelection()!.addRange(selected);
  }
};
