import Routes from './Routes';
import SubmitCV from '../features/SubmitCV/Loadable';
import VideoInterview from '../features/VideoInterview/Loadable';
import VideoInterviewAppointment from '../features/VideoInterviewAppointment/Loadable';
import VideoQuestion from '../features/VideoQuestion/Loadable';
import VidoeSuccess from '../features/VideoSuccess/Loadable';
import VideoUnavailable from '../features/VideoUnavailable/Loadable';
import VideoPassed from '../features/VideoPassed/Loadable';
import VideoReschedule from '../features/VideoReschedule/Loadable';

const Screens = {
  SubmitCV: {
    title: 'JetDevs - Submit CV',
    path: Routes.SubmitCV,
    component: SubmitCV,
  },
  VideoInterview: {
    title: 'JetDevs - Video Interview',
    path: Routes.VideoInterview,
    component: VideoInterview,
  },
  VideoInterviewAppointment: {
    title: 'JetDevs - Video Interview Appointment',
    path: Routes.VideoInterviewAppointment,
    component: VideoInterviewAppointment,
  },
  VideoInterviewReschedule: {
    title: 'JetDevs - Video Interview Reschedule',
    path: Routes.VideoInterviewReschedule,
    component: VideoReschedule,
  },
  Question: {
    title: 'JetDevs - Questioin',
    path: Routes.Question,
    component: VideoQuestion,
  },
  Success: {
    title: 'JetDevs - Success',
    path: Routes.Success,
    component: VidoeSuccess,
  },
  Unavailable: {
    title: 'JetDevs - Unavailable',
    path: Routes.Unavailable,
    component: VideoUnavailable,
  },
  Passed: {
    title: 'JetDevs - Passed',
    path: Routes.Passed,
    component: VideoPassed,
  },
};

export default Screens;
