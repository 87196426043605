import Logo from '../assets/images/latest-logo.svg';
import ZoomShareIcon from '../assets/images/icons/zoom-share.svg';
import StatusInterviewed from '../assets/images/icons/status-interviewed.svg';
import StatusExpired from '../assets/images/icons/status-expired.svg';
import ZoomLinkCopy from '../assets/images/icons/zoom-link-copy.svg';
import SayAnser from '../assets/images/icons/say-anwser.svg';
import Good from '../assets/images/icons/good.svg';
import Unavailable from '../assets/images/icons/unavailable.svg';
import Passed from '../assets/images/icons/passed.svg';
import Counter from '../assets/images/icons/counter-icon.svg';
import Demo from '../assets/images/icons/demo-icon.svg';
import Ensign from '../assets/images/icons/ensign-icon.svg';

const Images = {
  Logo,
  ZoomShareIcon,
  StatusInterviewed,
  StatusExpired,
  ZoomLinkCopy,
  SayAnser,
  Demo,
  Good,
  Counter,
  Unavailable,
  Passed,
  Ensign,
};

export default Images;
