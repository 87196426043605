import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import {
  VideoInterviewAppointmentInfoState,
  ErrorType,
} from '../../constants/types';
import VideoInterviewService from '../../services/VideoInterview';

/* eslint-disable no-param-reassign */
export interface QuestionPayloadState {
  code: string;
  question_id: number | null;
}

export interface VideoQuestonState {
  loading: boolean;
  error: ErrorType | null | undefined;
  info: VideoInterviewAppointmentInfoState | null;
}

const initialState: VideoQuestonState = {
  loading: false,
  error: null,
  info: null,
};

export const getVideoInterviewInfoAction = createAsyncThunk<
  any,
  string,
  {
    rejectValue: ErrorType;
  }
>(
  'videoReschedule/getVideoInterviewInfoAction',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await VideoInterviewService.doGetVideoInterviewInfo(
        payload,
      );
      if (response.success) {
        return response.results;
      }
      return rejectWithValue({
        message: response.message,
      });
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({
        message: err.response.data,
      });
    }
  },
);

export const videoRescheduleSlice = createSlice({
  name: 'videoReschedule',
  initialState,
  reducers: {
    clearAction: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideoInterviewInfoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVideoInterviewInfoAction.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(getVideoInterviewInfoAction.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error as ErrorType;
        }
      });
  },
});

export const { clearAction } = videoRescheduleSlice.actions;

export const selectLoading = (state: RootState) =>
  state.videoReschedule.loading;
export const selectInfo = (state: RootState) => state.videoReschedule.info;
export const selectError = (state: RootState) => state.videoReschedule.error;

export default videoRescheduleSlice.reducer;
