const Routes = {
  home: '/',
  SubmitCV: '/submit-cv/:type',
  VideoInterview: '/video-interview/:code',
  VideoInterviewAppointment: '/video-interview-appointment/:code',
  VideoInterviewReschedule: '/video-interview-reschedule/:code',
  Question: '/video-question/:code',
  Success: '/video-success',
  Unavailable: '/video-unavailable',
  Passed: '/video-passed',
};

export default Routes;
