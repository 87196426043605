const Colors = {
  black: '#000000',
  white: '#ffffff',
  grey: '#7D8D95',
  greyThin: '#9599A5',
  grey4: '#CED0D8',
  grey5: '#E5E6E9',
  primary: '#28518D',
  secondary: '#ED9617',
  secondaryHover: '#F0A63B',
  title: '#3B3F46',
  subTitle: '#626671',
};

export default Colors;
