import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import {
  VideoInterviewAppointmentInfoState,
  ErrorType,
} from '../../constants/types';
import VideoInterviewService from '../../services/VideoInterview';

/* eslint-disable no-param-reassign */
export interface VideoInterviewAppointmentTimeslotState {
  code: string;
  timezone: string;
  date: string;
}

export interface VideoInterviewAppointmentCreatState {
  code: string;
  timezone: string;
  date: string;
  slot: string;
}

export interface VideoInterviewAppointmentState {
  loading: boolean;
  info: VideoInterviewAppointmentInfoState | null;
  timeslotLoading: boolean;
  error: ErrorType | null | undefined;
  timeslots: Array<{ slot: string; status: boolean }>;
  creating: boolean;
  done: boolean;
}

const initialState: VideoInterviewAppointmentState = {
  loading: false,
  info: null,
  timeslotLoading: false,
  timeslots: [],
  error: null,
  creating: false,
  done: false,
};

export const getVideoInterviewInfoAction = createAsyncThunk<
  any,
  string,
  {
    rejectValue: ErrorType;
  }
>(
  'videoInterviewAppointment/getVideoInterviewInfoAction',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await VideoInterviewService.doGetVideoInterviewInfo(
        payload,
      );
      if (response.success) {
        return response.results;
      }
      return rejectWithValue({
        message: response.message,
      });
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({
        message: err.response.data,
      });
    }
  },
);

export const createVideoInterviewInfoAction = createAsyncThunk<
  any,
  VideoInterviewAppointmentCreatState,
  {
    rejectValue: ErrorType;
  }
>(
  'videoInterviewAppointment/createVideoInterviewInfoAction',
  async (payload: VideoInterviewAppointmentCreatState, { rejectWithValue }) => {
    try {
      const response =
        await VideoInterviewService.doCreateVideoInterviewAppointment(payload);
      if (response.success) {
        return response.results;
      }
      return rejectWithValue({
        message: response.message,
      });
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({
        message: err.response.data,
      });
    }
  },
);

export const getVideoInterviewAppointmentTimeslotsAction = createAsyncThunk<
  any,
  VideoInterviewAppointmentTimeslotState,
  {
    rejectValue: ErrorType;
  }
>(
  'videoInterviewAppointment/getVideoInterviewAppointmentTimeslotsAction',
  async (
    payload: VideoInterviewAppointmentTimeslotState,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await VideoInterviewService.doGetVideoInterviewAppointmentTimeslots(
          payload,
        );
      if (response.success) {
        return response.results;
      }
      return rejectWithValue({
        message: response.message,
      });
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({
        message: err.response.data,
      });
    }
  },
);

export const videoInterviewAppointmentSlice = createSlice({
  name: 'videoInterviewAppointment',
  initialState,
  reducers: {
    clearAction: (state) => {
      state.loading = false;
      state.creating = false;
      state.error = null;
      state.done = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVideoInterviewInfoAction.pending, (state) => {
        state.creating = true;
      })
      .addCase(createVideoInterviewInfoAction.fulfilled, (state, action) => {
        state.creating = false;
        state.done = action.payload;
      })
      .addCase(createVideoInterviewInfoAction.rejected, (state, action) => {
        state.creating = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error as ErrorType;
        }
      })
      .addCase(getVideoInterviewInfoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVideoInterviewInfoAction.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(getVideoInterviewInfoAction.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error as ErrorType;
        }
      })
      .addCase(getVideoInterviewAppointmentTimeslotsAction.pending, (state) => {
        state.timeslotLoading = true;
      })
      .addCase(
        getVideoInterviewAppointmentTimeslotsAction.fulfilled,
        (state, action) => {
          state.timeslotLoading = false;
          state.timeslots = action.payload;
        },
      )
      .addCase(
        getVideoInterviewAppointmentTimeslotsAction.rejected,
        (state, action) => {
          state.timeslotLoading = false;
          if (action.payload) {
            state.error = action.payload;
          } else {
            state.error = action.error as ErrorType;
          }
        },
      );
  },
});

export const { clearAction } = videoInterviewAppointmentSlice.actions;

export const selectLoading = (state: RootState) =>
  state.videoInterviewAppointment.loading;
export const selectInfo = (state: RootState) =>
  state.videoInterviewAppointment.info;
export const selectTimeslotLoading = (state: RootState) =>
  state.videoInterviewAppointment.timeslotLoading;
export const selectTimeslots = (state: RootState) =>
  state.videoInterviewAppointment.timeslots;
export const selectError = (state: RootState) =>
  state.videoInterviewAppointment.error;
export const selectCreating = (state: RootState) =>
  state.videoInterviewAppointment.creating;
export const selectDone = (state: RootState) =>
  state.videoInterviewAppointment.done;

export default videoInterviewAppointmentSlice.reducer;
