import { connectRouter } from 'connected-react-router';
import { combineReducers } from '@reduxjs/toolkit';

import submitCVReducer from '../features/SubmitCV/SubmitCV.slice';
import videoInterviewReducer from '../features/VideoInterview/VideoInterview.slice';
import videoInterviewAppointmentReducer from '../features/VideoInterviewAppointment/VideoInterviewAppointment.slice';
import videoQuestionReducer from '../features/VideoQuestion/VideoQuestion.slice';
import videoRescheduleReducer from '../features/VideoReschedule/VideoReschedule.slice';

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    submitCV: submitCVReducer,
    videoInterview: videoInterviewReducer,
    videoInterviewAppointment: videoInterviewAppointmentReducer,
    videoQuestion: videoQuestionReducer,
    videoReschedule: videoRescheduleReducer,
  });

export default createRootReducer;
