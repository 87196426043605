import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import history from './utils/history';
import Screens from './navigation/Screens';
import PageContainer from './components/PageContainer';
import Header from './components/Header';
import PageContent from './components/PageContent';
import './App.css';
declare global {
  interface Window {
    timer: any;
    headerCounter: any;
  }
}

const renderPage = (routeProps: any, Component: any) => (
  <PageContainer>
    <Header />
    <PageContent>
      <Component {...routeProps} />
    </PageContent>
  </PageContainer>
);

const getRoutes = () => {
  const routes = Object.entries(Screens).map((item) => {
    const [name, props] = item;
    const { path, component: Component, ...optProps } = props;
    return (
      <Route
        key={name}
        path={path}
        render={(routeProps) => renderPage(routeProps, Component)}
        {...optProps}
      />
    );
  });
  return routes;
};

const App = () => (
  <ConnectedRouter history={history}>
    <Switch>{getRoutes()}</Switch>
  </ConnectedRouter>
);

export default App;
