import { RequestClientClass } from '../../utils/requestClient';
import { API_SERVER } from '../../constants/predicates';
import API from '../../constants/API';
import { SubmitCVPayload } from '../../features/SubmitCV/SubmitCV.slice';

const requestClient = () => new RequestClientClass(API_SERVER);

const doCheckEmail = async (payload: SubmitCVPayload) => {
  const uri = API.submitCV.post;
  const response = await requestClient()
    .setUri(uri)
    .setPayload(payload)
    .doPost();
  return response;
};

export default {
  doCheckEmail,
};
