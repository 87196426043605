import { RequestClientClass } from '../../utils/requestClient';
import { API_SERVER } from '../../constants/predicates';
import API from '../../constants/API';
import {
  VideoInterviewAppointmentTimeslotState,
  VideoInterviewAppointmentCreatState,
} from '../../features/VideoInterviewAppointment/VideoInterviewAppointment.slice';
import { QuestionPayloadState } from '../../features/VideoQuestion/VideoQuestion.slice';

const requestClient = () => new RequestClientClass(API_SERVER);

const doGetVideoInterviewInfo = async (code: string) => {
  const uri = API.videoInterviewInfo.get.replace(':code', code);
  const response = await requestClient().setUri(uri).doGet();
  return response;
};

const doGetVideoInterviewAppointmentTimeslots = async (
  payload: VideoInterviewAppointmentTimeslotState,
) => {
  const uri = API.timeslot.get.replace(':code', payload.code);
  const response = await requestClient()
    .setUri(uri)
    .setQueryParameter(payload)
    .doGet();
  return response;
};

const doGetVideoInterview = async (code: string) => {
  const uri = API.videoInterview.get;
  const response = await requestClient()
    .setQueryParameter({ code })
    .setUri(uri)
    .doGet();
  return response;
};

const doCreateVideoInterviewAppointment = async (
  payload: VideoInterviewAppointmentCreatState,
) => {
  const uri = API.appointment.post.replace(':code', payload.code);
  const response = await requestClient()
    .setUri(uri)
    .setPayload(payload)
    .doPost();
  return response;
};

const doCreateVideoInterviewZoom = async (code: string) => {
  const uri = API.zoomCreate.post.replace(':code', code);
  const response = await requestClient().setUri(uri).doPost();
  return response;
};

const doCreateVideoInterviewQuestion = async (
  payload: QuestionPayloadState,
) => {
  const uri = API.question.post.replace(':code', payload.code);
  const response = await requestClient()
    .setUri(uri)
    .setPayload(payload)
    .doPost();
  return response;
};

const doSaveWrittenAnswer = async (payload: QuestionPayloadState) => {
  const uri = API.autoSaveWrittenAnswer.post.replace(':code', payload.code);
  const response = await requestClient()
    .setUri(uri)
    .setPayload(payload)
    .doPost();
  return response;
};

const doGetVideoInterviewZoomStatus = async (code: string) => {
  const uri = API.zoomStatus.get.replace(':code', code);
  const response = await requestClient().setUri(uri).doGet();
  return response;
};

export default {
  doGetVideoInterviewInfo,
  doGetVideoInterview,
  doGetVideoInterviewAppointmentTimeslots,
  doCreateVideoInterviewAppointment,
  doCreateVideoInterviewZoom,
  doCreateVideoInterviewQuestion,
  doSaveWrittenAnswer,
  doGetVideoInterviewZoomStatus,
};
