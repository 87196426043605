export const VideoInterviewStatus = {
  new: 'new',
  pending: 'pending',
  interviewing: 'interviewing',
  finished: 'finished',
  autoFinished: 'auto_finished',
  expired: 'expired',
  canceled: 'canceled',
  unavailable: 'unavailable',
  passed: 'passed',
};

export const VideoQuestionType = {
  talk: 'talk',
  written: 'written',
  selfIntro: 'self_intro',
  stepIntro: 'step_intro',
  demo: 'demo',
  radio: 'radio',
  checkbox: 'checkbox',
  short_answer: 'short_answer',
  long_answer: 'long_answer',
};

export const DefaultTimeZone = 'Asia/Kolkata';

export const Timezones = [
  {
    offset: 'Etc/GMT+12',
    title: '(UTC-12:00) International Date Line West',
  },
  {
    offset: 'Etc/GMT+11',
    title: '(UTC-11:00) Coordinated Universal Time-11',
  },
  {
    offset: 'Pacific/Honolulu',
    title: '(UTC-10:00) Hawaii',
  },
  {
    offset: 'America/Anchorage',
    title: '(UTC-09:00) Alaska',
  },
  {
    offset: 'America/Santa_Isabel',
    title: '(UTC-08:00) Baja California',
  },
  {
    offset: 'America/Los_Angeles',
    title: '(UTC-08:00) Pacific Time (US and Canada)',
  },
  {
    offset: 'America/Chihuahua',
    title: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  },
  {
    offset: 'America/Phoenix',
    title: '(UTC-07:00) Arizona',
  },
  {
    offset: 'America/Denver',
    title: '(UTC-07:00) Mountain Time (US and Canada)',
  },
  {
    offset: 'America/Guatemala',
    title: '(UTC-06:00) Central America',
  },
  {
    offset: 'America/Chicago',
    title: '(UTC-06:00) Central Time (US and Canada)',
  },
  {
    offset: 'America/Regina',
    title: '(UTC-06:00) Saskatchewan',
  },
  {
    offset: 'America/Mexico_City',
    title: 'Guadalajara, Mexico City, Monterey',
  },
  {
    offset: 'America/Bogota',
    title: '(UTC-05:00) Bogota, Lima, Quito',
  },
  {
    offset: 'America/Indiana/Indianapolis',
    title: '(UTC-05:00) Indiana (East)',
  },
  {
    offset: 'America/New_York',
    title: '(UTC-05:00) Eastern Time (US and Canada)',
  },
  {
    offset: 'America/Caracas',
    title: '(UTC-04:30) Caracas',
  },
  {
    offset: 'America/Halifax',
    title: '(UTC-04:00) Atlantic Time (Canada)',
  },
  {
    offset: 'America/Asuncion',
    title: '(UTC-04:00) Asuncion',
  },
  {
    offset: 'America/La_Paz',
    title: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  },
  {
    offset: 'America/Cuiaba',
    title: '(UTC-04:00) Cuiaba',
  },
  {
    offset: 'America/Santiago',
    title: '(UTC-04:00) Santiago',
  },
  {
    offset: 'America/St_Johns',
    title: '(UTC-03:30) Newfoundland',
  },
  {
    offset: 'America/Sao_Paulo',
    title: '(UTC-03:00) Brasilia',
  },
  {
    offset: 'America/Godthab',
    title: '(UTC-03:00) Greenland',
  },
  {
    offset: 'America/Cayenne',
    title: '(UTC-03:00) Cayenne, Fortaleza',
  },
  {
    offset: 'America/Argentina/Buenos_Aires',
    title: '(UTC-03:00) Buenos Aires',
  },
  {
    offset: 'America/Montevideo',
    title: '(UTC-03:00) Montevideo',
  },
  {
    offset: 'Etc/GMT+2',
    title: '(UTC-02:00) Coordinated Universal Time-2',
  },
  {
    offset: 'Atlantic/Cape_Verde',
    title: '(UTC-01:00) Cape Verde',
  },
  {
    offset: 'Atlantic/Azores',
    title: '(UTC-01:00) Azores',
  },
  {
    offset: 'Africa/Casablanca',
    title: '(UTC+00:00) Casablanca',
  },
  {
    offset: 'Atlantic/Reykjavik',
    title: '(UTC+00:00) Monrovia, Reykjavik',
  },
  {
    offset: 'Europe/London',
    title: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
  },
  {
    offset: 'Etc/GMT',
    title: '(UTC+00:00) Coordinated Universal Time',
  },
  {
    offset: 'Europe/Berlin',
    title: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    offset: 'Europe/Paris',
    title: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    offset: 'Africa/Lagos',
    title: '(UTC+01:00) West Central Africa',
  },
  {
    offset: 'Europe/Budapest',
    title: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    offset: 'Europe/Warsaw',
    title: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    offset: 'Africa/Windhoek',
    title: '(UTC+01:00) Windhoek',
  },
  {
    offset: 'Europe/Istanbul',
    title: '(UTC+02:00) Athens, Bucharest, Istanbul',
  },
  {
    offset: 'Europe/Kiev',
    title: '(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    offset: 'Africa/Cairo',
    title: '(UTC+02:00) Cairo',
  },
  {
    offset: 'Asia/Damascus',
    title: '(UTC+02:00) Damascus',
  },
  {
    offset: 'Asia/Amman',
    title: '(UTC+02:00) Amman',
  },
  {
    offset: 'Africa/Johannesburg',
    title: '(UTC+02:00) Harare, Pretoria',
  },
  {
    offset: 'Asia/Jerusalem',
    title: '(UTC+02:00) Jerusalem',
  },
  {
    offset: 'Asia/Beirut',
    title: '(UTC+02:00) Beirut',
  },
  {
    offset: 'Asia/Baghdad',
    title: '(UTC+03:00) Baghdad',
  },
  {
    offset: 'Europe/Minsk',
    title: '(UTC+03:00) Minsk',
  },
  {
    offset: 'Asia/Riyadh',
    title: '(UTC+03:00) Kuwait, Riyadh',
  },
  {
    offset: 'Africa/Nairobi',
    title: '(UTC+03:00) Nairobi',
  },
  {
    offset: 'Asia/Tehran',
    title: '(UTC+03:30) Tehran',
  },
  {
    offset: 'Europe/Moscow',
    title: '(UTC+04:00) Moscow, St. Petersburg, Volgograd',
  },
  {
    offset: 'Asia/Tbilisi',
    title: '(UTC+04:00) Tbilisi',
  },
  {
    offset: 'Asia/Yerevan',
    title: '(UTC+04:00) Yerevan',
  },
  {
    offset: 'Asia/Dubai',
    title: '(UTC+04:00) Abu Dhabi, Muscat',
  },
  {
    offset: 'Asia/Baku',
    title: '(UTC+04:00) Baku',
  },
  {
    offset: 'Indian/Mauritius',
    title: '(UTC+04:00) Port Louis',
  },
  {
    offset: 'Asia/Kabul',
    title: '(UTC+04:30) Kabul',
  },
  {
    offset: 'Asia/Tashkent',
    title: '(UTC+05:00) Tashkent',
  },
  {
    offset: 'Asia/Karachi',
    title: '(UTC+05:00) Islamabad, Karachi',
  },
  {
    offset: 'Asia/Colombo',
    title: '(UTC+05:30) Sri Jayewardenepura Kotte',
  },
  {
    offset: 'Asia/Kolkata',
    title: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    offset: 'Asia/Kathmandu',
    title: '(UTC+05:45) Kathmandu',
  },
  {
    offset: 'Asia/Almaty',
    title: '(UTC+06:00) Astana',
  },
  {
    offset: 'Asia/Dhaka',
    title: '(UTC+06:00) Dhaka',
  },
  {
    offset: 'Asia/Yekaterinburg',
    title: '(UTC+06:00) Yekaterinburg',
  },
  {
    offset: 'Asia/Yangon',
    title: '(UTC+06:30) Yangon',
  },
  {
    offset: 'Asia/Bangkok',
    title: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  },
  {
    offset: 'Asia/Novosibirsk',
    title: '(UTC+07:00) Novosibirsk',
  },
  {
    offset: 'Asia/Krasnoyarsk',
    title: '(UTC+08:00) Krasnoyarsk',
  },
  {
    offset: 'Asia/Ulaanbaatar',
    title: '(UTC+08:00) Ulaanbaatar',
  },
  {
    offset: 'Asia/Shanghai',
    title: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    offset: 'Australia/Perth',
    title: '(UTC+08:00) Perth',
  },
  {
    offset: 'Asia/Singapore',
    title: '(UTC+08:00) Kuala Lumpur, Singapore',
  },
  {
    offset: 'Asia/Taipei',
    title: '(UTC+08:00) Taipei',
  },
  {
    offset: 'Asia/Irkutsk',
    title: '(UTC+09:00) Irkutsk',
  },
  {
    offset: 'Asia/Seoul',
    title: '(UTC+09:00) Seoul',
  },
  {
    offset: 'Asia/Tokyo',
    title: '(UTC+09:00) Osaka, Sapporo, Tokyo',
  },
  {
    offset: 'Australia/Darwin',
    title: '(UTC+09:30) Darwin',
  },
  {
    offset: 'Australia/Adelaide',
    title: '(UTC+09:30) Adelaide',
  },
  {
    offset: 'Australia/Hobart',
    title: '(UTC+10:00) Hobart',
  },
  {
    offset: 'Asia/Yakutsk',
    title: '(UTC+10:00) Yakutsk',
  },
  {
    offset: 'Australia/Brisbane',
    title: '(UTC+10:00) Brisbane',
  },
  {
    offset: 'Pacific/Port_Moresby',
    title: '(UTC+10:00) Guam, Port Moresby',
  },
  {
    offset: 'Australia/Sydney',
    title: '(UTC+10:00) Canberra, Melbourne, Sydney',
  },
  {
    offset: 'Asia/Vladivostok',
    title: '(UTC+11:00) Vladivostok',
  },
  {
    offset: 'Pacific/Guadalcanal',
    title: '(UTC+11:00) Solomon Islands, New Caledonia',
  },
  {
    offset: 'Etc/GMT-12',
    title: '(UTC+12:00) Coordinated Universal Time+12',
  },
  {
    offset: 'Pacific/Fiji',
    title: '(UTC+12:00) Fiji, Marshall Islands',
  },
  {
    offset: 'Asia/Magadan',
    title: '(UTC+12:00) Magadan',
  },
  {
    offset: 'Pacific/Auckland',
    title: '(UTC+12:00) Auckland, Wellington',
  },
  {
    offset: 'Pacific/Tongatapu',
    title: `(UTC+13:00) Nuku'alofa`,
  },
  {
    offset: 'Pacific/Apia',
    title: `(UTC+13:00) Samoa`,
  },
];

export const PromptOne =
  'Please contact <a href="mailto:ops@jetdevs.com" target="_blank">ops@jetdevs.com</a> for assistance.';
