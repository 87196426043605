const API = {
  submitCV: {
    post: 'v1/customer/vetting/candidate/email',
  },
  videoInterview: {
    get: 'v1/customer/vetting/candidate/email',
  },
  videoInterviewInfo: {
    get: 'v1/interview/:code',
  },
  appointment: {
    post: 'v1/interview/:code/appointment',
  },
  timeslot: {
    get: 'v1/interview/:code/timeslot',
  },
  zoomCreate: {
    post: 'v1/interview/:code/zoom_link',
  },
  zoomStatus: {
    get: 'v1/interview/:code/zoom_meetings',
  },
  question: {
    post: 'v1/interview/:code/question',
  },
  autoSaveWrittenAnswer: {
    post: 'v1/interview/:code/question',
  },
};

export default API;
