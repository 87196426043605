import styled from 'styled-components';

const PageContent = styled.div`
  margin: 0 auto;
  display: block;
  min-height: 100vh;
  @media (max-width: 1280px) {
    padding: 0 120px;
  }
  @media (max-width: 768px) {
    padding: 0 24px;
  }
  @media (max-width: 576px) {
    padding: 0 20px;
  }
`;

export default PageContent;
